@font-face {
  font-family: 'Inconsolata';
  src: local('Inconsolata'), url(./fonts/Inconsolata-VariableFont_wdth,wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url(./fonts/Ubuntu-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu-Bold';
  src: local('Ubuntu-Bold'), url(./fonts/Ubuntu-Bold.ttf) format('truetype');
}